/**
 * Handles the newsletter form submission
 */
$(function () {
  console.log('Newsletter script loaded');

  const form = $('#newsletter-form');
  const responseContainer = $('#newsletter-response');
  const submitButton = form.find('button[type="submit"]');

  if (form.length) {
    console.log('Newsletter form found');

    // Handle the form submission
    form.on('submit', function (e) {
      e.preventDefault(); // Prevent the default form submission

      // console.log(app_params); //eslint-disable-line
      const email = form.find('input[name="email"]').val();
      const data = {
        action: 'subscribe_newsletter', // WordPress action hook. @see wp_ajax_subscribe_newsletter and wp_ajax_nopriv_subscribe_newsletter
        email: email,
        // Use the localized nonce
        nonce: app_params.newsletter_nonce, //eslint-disable-line
      };

      // Disable the submit button and show a loading indicator
      submitButton.prop('disabled', true).html(`
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Suscribiéndote...
          `);

      // Send the AJAX request
      $.ajax({
        //Use the localized AJAX URL
        url: app_params.ajaxurl, //eslint-disable-line
        data: data, // Data to send in the AJAX request
        type: 'POST',
        /**
         * Handle the successful response from the AJAX request.
         *
         * @param {object} response The AJAX response object.
         */
        success: function (response) {
          console.log('Full response:', response); // Log the entire response
          if (response.success) {
            console.log('Response success:', response.data.message);
            responseContainer.html(
              `<p class="text-green-500">${response.data.message}</p>`,
            );
            form[0].reset(); // Reset the form
          } else {
            console.error('Response error:', response.data.message);
            responseContainer.html(
              `<p class="text-red-500">${response.data.message}</p>`,
            );
          }
        },

        /**
         * Handle the error response from the AJAX request.
         *
         * @param {jqXHR} xhr The jqXHR object.
         * @param {string} status The status of the response.
         * @param {string} error The error message.
         */
        error: function (xhr, status, error) {
          console.error('Error:', error, xhr, status);
          responseContainer.html(
            `<p class="text-red-500">Algo salió mal. Por favor inténtalo de nuevo.</p>`,
          );
        },
        /**
         * Callback function to execute when the AJAX request is complete.
         *
         * This function simply re-enables the submit button.
         */
        complete: function () {
          // Re-enable the submit button
          // submitButton.prop('disabled', false).text('Suscribir');
        },
      });
    });
  }
});
