import domReady from '@roots/sage/client/dom-ready';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import 'slick-carousel';

/**
 * Application entrypoint
 */
domReady(async () => {
  // Inicializa Alpine antes de cualquier manipulación del DOM
  window.Alpine = Alpine;
  Alpine.plugin(collapse);
  Alpine.start();

  //MOBILE BURGER
  jQuery('#burger-open').on('click', function () {
    jQuery('#burger-wrap').fadeIn();
  });
  jQuery('#burger-close').on('click', function () {
    jQuery('#burger-wrap').fadeOut();
  });

  // CHECKOUT PAGE
  // Automatic redirect to Wompi after checkout
  if (jQuery('body').hasClass('woocommerce-order-pay')) {
    // Wait for 2 seconds (2000 milliseconds)
    setTimeout(function () {
      // Trigger a click event on the button with class jQuery.waybox-button
      jQuery('.waybox-button').click();
    }, 500);
  }

  /**
   * WC VARIATIONS AS RATIO BUTTONS
   * @link https://stackoverflow.com/a/53073346/1489879
   *
   * These JavaScript functions are responsible for handling interactions and updates
   * related to variation attributes and options on a WooCommerce product page.
   *
   * 1. The first function listens for changes in variation radio buttons and synchronizes
   *    the selected values with corresponding select elements, triggering change events.
   *
   * 2. The second function responds to WooCommerce's 'woocommerce_update_variation_values'
   *    event and manages the enabling/disabling of variation radio buttons based on the
   *    availability of options in the associated select elements.
   */

  // Handle changes in variation radio buttons.
  jQuery(document).on('change', '.variation-radios input', function () {
    // Loop through checked radio buttons.
    jQuery('.variation-radios input:checked').each(function (index, element) {
      var jQueryel = jQuery(element);
      var thisName = jQueryel.attr('name');
      var thisVal = jQueryel.attr('value');

      // Set the corresponding select element's value and trigger a change event.
      jQuery('select[name="' + thisName + '"]')
        .val(thisVal)
        .trigger('change');
    });
  });

  // Handle updates to variation values triggered by WooCommerce.
  jQuery(document).on('woocommerce_update_variation_values', function () {
    // Loop through all variation radio buttons.
    jQuery('.variation-radios input').each(function (index, element) {
      var jQueryel = jQuery(element);
      var thisName = jQueryel.attr('name');
      var thisVal = jQueryel.attr('value');

      // Remove the 'disabled' attribute from the radio button.
      jQueryel.removeAttr('disabled');

      // Check if the corresponding option in the select element is disabled.
      if (
        jQuery(
          'select[name="' + thisName + '"] option[value="' + thisVal + '"]',
        ).is(':disabled')
      ) {
        // If disabled, set the 'disabled' property for the radio button.
        jQueryel.prop('disabled', true);
      }
    });
  });

  // This function adds interactivity to radio button options for product variations.
  // When a user clicks on a variation option, it selects the corresponding radio button,
  // highlights the selected option, and triggers a 'change' event on the radio button.
  function handleVariationOptions() {
    var container = jQuery('.variation-radios .variation-option');
    var classes = 'ring-2 ring-indigo-500';

    // Loop through each variation option.
    container.each(function () {
      var input = jQuery(this).find('input');

      // Initialize options on page load.
      if (input.prop('checked')) {
        jQuery(this).addClass(classes);
      }

      // Toggle checked on input and update classes on click.
      jQuery(this).on('click', function () {
        if (!input.prop('checked')) {
          input.prop('checked', true);
          container.removeClass(classes);
          jQuery(this).addClass(classes);
          input.trigger('change');
        }
      });
    });
  }

  handleVariationOptions();

  // SLICK SLIDER
  //Slider with default options
  function snbrSlider() {
    if (jQuery('.slider')) {
      jQuery('.slider').slick({
        dots: true,
        infinite: true,
        slidesToShow: 1,
        fade: true,
        cssEase: 'linear',
        arrows: false,
        autoplay: true,
      });
    }
  }
  //Slider with no options so it can be overriden by data attributes
  function dataSlider() {
    // Check if any elements with the class .data-slider exist
    if (jQuery('.data-slider').length > 0) {
      // Initialize Slick for all .data-slider elements
      jQuery('.data-slider').slick();
    }
  }

  // Call the function to initialize the sliders
  dataSlider();
  snbrSlider();

  //PRODUCT GALLERY SINCED SLIDERS
  function productGallery() {
    jQuery('.product-gallery-slider').slick({
      dots: false,
      infinite: false,
      slidesToShow: 1,
      asNavFor: '.product-thumb-slider',
      fade: true,
      cssEase: 'linear',
      arrows: false,
      autoplay: true,
    });
  }
  function productThumbGallery() {
    jQuery('.product-thumb-slider').slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: '.product-gallery-slider',
      infinite: false,
      dots: false,
      arrows: false,
      focusOnSelect: true,
    });
  }
  productThumbGallery();
  productGallery();

  // When a FAQ question is clicked
  jQuery(document).on('click', '.faq-question', function (e) {
    // Prevent the default behavior of the link
    e.preventDefault();
    // Toggle the rotation of the icon
    jQuery(this).find('.faq-icon').toggleClass('rotate-180');
    // Toggle the visibility of the corresponding answer
    jQuery(this).next('.faq-answer').slideToggle();
  });

  // jQuery smooth scroll to anchor
  jQuery('a[href*="#"]').on('click', function (e) {
    e.preventDefault();

    jQuery('html, body').animate(
      {
        scrollTop: jQuery(jQuery(this).attr('href')).offset().top,
      },
      500,
      'linear',
    );
  });

  jQuery(window).on('load', function () {
    console.debug('Window loaded. Lazy loading responsive backgrounds...');

    // Function to load the correct background image
    function loadBgImage($el) {
      const bgUrl = window.matchMedia('(min-width: 1024px)').matches
        ? $el.data('bg-lg') || $el.data('bg') // Large screen or fallback
        : $el.data('bg'); // Default (mobile) image

      if (bgUrl) {
        $el.css('background-image', `url('${bgUrl}')`);
        console.debug('Loaded background for:', bgUrl);
        $el.removeAttr('data-bg').removeAttr('data-bg-lg'); // Clean up
      } else {
        console.warn('Missing data-bg attribute on:', $el);
      }
    }

    // Load backgrounds on page load
    jQuery('.lazy-bg').each(function () {
      loadBgImage(jQuery(this));
    });

    // Watch for dynamically added .lazy-bg elements
    const observer = new MutationObserver(function () {
      jQuery('.lazy-bg[data-bg], .lazy-bg[data-bg-lg]').each(function () {
        loadBgImage(jQuery(this));
      });
    });

    observer.observe(document.body, {childList: true, subtree: true});

    // Safety fallback after 5s in case of missed elements
    setTimeout(function () {
      jQuery('.lazy-bg[data-bg], .lazy-bg[data-bg-lg]').each(function () {
        console.warn('Fallback triggered for:', jQuery(this).data('bg'));
        loadBgImage(jQuery(this));
      });
    }, 5000);

    // Optional: Reload images on window resize
    jQuery(window).on('resize', function () {
      jQuery('.lazy-bg').each(function () {
        loadBgImage(jQuery(this));
      });
    });
  });
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
